<template>
	<div class="bigBox">
		<el-page-header @back="goBack" content="油脂消耗统计"> </el-page-header>
		<!-- <h2>油脂消耗统计</h2> -->
		<div class="flex-sb">
			<div class="row-me cont">
				<el-form ref="form" :model="form" label-width="80px" style="display: flex;align-items: center;">
					<el-date-picker v-model="form.value1" type="daterange" align="center" range-separator="~"
						start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD">
					</el-date-picker>
					<el-select v-model="form.device_id" placeholder="选择设备" class="electIpt" @focus="comSelect">
						<el-option v-for="(item,index) in comList" :key="index" :value="item.name">
						</el-option>
					</el-select>
					<el-button type="primary" class="searchs" @click="search">
						<img src="../../assets/img/icon_sousuo_b.png" alt="" />
						搜索
					</el-button>
					<!-- <el-button type="primary" icon="Search" class="searchs" @click="search">搜索</el-button> -->
					<el-button class="agains" @click="resetBtn">重置
					</el-button>
				</el-form>
			</div>
			<div style="float: right;margin-top: 23px;" class="row-me row-center">
				<div class="bold">
					设备总数： <span>{{allListNumber}}</span>
				</div>
				<div class="bold">
					消耗油脂量（ml）： <span>{{allGreaseValue}}</span>
				</div>
				<div class="imgbold pointer" @click="consumption">
					<img src="../../assets/img/icon_dc.png" class="img" />&nbsp;
					<span class="youzhi">导出</span>
				</div>
			</div>
		</div>

		<div class="column-me margin-top20">
			<div class="table-border">
				<el-table :data="tableData" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="selection" width="70">
					</el-table-column>
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="ieme_encoded" label="IEMI编码" align="center">
					</el-table-column>
					<el-table-column prop="device_encoded" label="设备编码" align="center">
					</el-table-column>
					<el-table-column prop="name" label="设备名称" align="center">
					</el-table-column>
					<el-table-column prop="grease_value" label="消耗油脂量（ml）" align="center">
					</el-table-column>
				</el-table>
			</div>
			<div class="row-me row-center flex-end1 margin-top10" v-if="tableData.length>0">
				<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
					:current-page.sync="pageNo" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					value1: [],
					ieme_encoded: "",
					company_id: "",
				},
				tableData: [],
				comList: [],
				// 分页开始
				pageNo: 1,
				total: 0,
				allGreaseValue: '', //总的油脂消耗
				allListNumber: '', //设备总数
			}
		},
		mounted() {
			this.getDeviceTabList()
			this.deviceInfoOptionWay()
		},
		methods: {
			getDeviceTabList() {
				this.$post("/index/deviceGreaseListInfo", {
					device_id: this.form.device_id,
					startTime: this.form.value1[0] ? this.form.value1[0] : '',
					endTime: this.form.value1[1] ? this.form.value1[1] : '',
					page: this.pageNo,
				}).then((res) => {
					if (res) {
						this.total = res.data.allListNumber;
						this.tableData = res.data.listInfo;
						this.allGreaseValue = res.data.allGreaseValue;
						this.allListNumber = res.data.allListNumber;
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getDeviceTabList()
			},
			goBack() {
				this.$router.go(-1);
			},
			deviceInfoOptionWay() {
				this.$post("/index/deviceInfoOption", {}).then((res) => {
					if (res) {
						this.comList = res.data;
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 所属公司下拉框
			comSelect() {

			},
			// 搜索
			search() {
				this.getDeviceTabList();
			},
			// 重置
			resetBtn() {
				this.form = {};
				this.form.value1 = [];
				this.getDeviceTabList();
			},
			consumption() {
				this.form.start_time = this.form.value1[0]
				this.form.end_time = this.form.value1[1]
				this.form.indexToken = sessionStorage.getItem('token')
				window.open(this.$axios.defaults.baseURL + '/index/deviceGreaseListInfoImport?' + this.$method
					.getParamsString(this.form));
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	/deep/.el-date-editor {
		width: 360px !important;
		height: 40px !important;
	}

	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.bold {
		font-weight: bold;
		padding: 0px 20px;
		margin-right: 10px;
	}

	.imgbold {
		display: flex;
		align-items: center;
		border: 1px solid #D4D7E0;
		padding: 7px 10px;
		border-radius: 4px;

		.img {
			width: 26px;
			height: 26px;
		}
	}

	h2 {
		margin-top: 20px;
	}

	.cont {
		margin-top: 20px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;

		span {
			margin-left: 0 !important;
		}

		img {
			width: 16px;
			height: 16px;
			margin-right: 10px;
		}
	}

	.agains {
		margin-left: 10px;
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-date-editor {
		width: 240px;
	}
</style>